import * as React from 'react';
import { Helmet } from 'react-helmet';


const pageStyles = {
  color: '#232129',
  padding: '96px',
  fontFamily: '-apple-system, Roboto, sans-serif, serif',
}

const iframeStyles = {
  bottom: '0',
  background: 'black',
  left: '0',
  position: 'absolute',
  right: '0',
  top: '0',
};

// markup
const FullSpectrumBMTraining = () => {
  return (
    <main style={pageStyles}>
      <Helmet
        title='Koalafi Demo - Lease, Brick and Mortar - Koalafi'
        meta={[
          {
            name: 'robots',
            content: 'noindex',
          },
        ]}
      />

      <iframe
        style={iframeStyles}
        width="100%"
        height="100%"
        src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FwKXR7hJfJ2aGfcaSxkvogL%2FFullSpectrum-B%2526M-Training-Demo%3Fnode-id%3D66%253A33%26scaling%3Dscale-down-width%26hide-ui%3D1"
        allowFullScreen
      />
    </main>
  );
};

export default FullSpectrumBMTraining;